import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { patientFullTextAction, patientSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'careHome', 'fulltext', 'patients')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    private careHomes: ImpiloDb.Tables.CareHome[] = [];
    private subGroups: ImpiloDb.Tables.SubGroup[] = [];
    private packingGroups: ImpiloDb.Tables.PackingGroup[] = [];

    @observable private selectedCareHome: Partial<ImpiloDb.Tables.CareHome> = null;
    @observable private selectedSubGroup: Partial<ImpiloDb.Tables.SubGroup> = null;
    @observable private selectedPackingGroup: Partial<ImpiloDb.Tables.PackingGroup> = null;

    @observable isActive = true;

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {

            const filter = this.filter;

            if (this.filter.trim().length >= 0) {
                this.isWaiting = true;

                const response = await this.repository.filterPatient(filter, this.isActive, this.selectedCareHome?.guid, this.selectedSubGroup?.guid, this.selectedPackingGroup?.guid, page, size);
                this.isWaiting = false;

                await patientSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((patient: ImpiloDb.Tables.Patient) => {
                    return patient;
                });

                return response;
            }
        }, () => patientFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }

    async attachedDone() {
        if (this.pager) {

            const packingGroups = (await (this.repository as ImpiloRepository).getPackingGroups()).results.map((value) => {
                value.option = value.name;
                return value;
            }).sort((a, b) => a.name.localeCompare(b.name));
            this.packingGroups = packingGroups;

            const careHomes = (await (this.repository as ImpiloRepository).getCareHomes()).results.map((careHome) => {
                careHome.option = careHome.name;
                return careHome;
            }).filter((a) => a.isActive).sort((a, b) => a.name.localeCompare(b.name));
            this.careHomes = careHomes;

            return this.pager.gotoFirst();
        }
    }

    async selectedCareHomeChanged(newValue, oldValue) {
        if (newValue && oldValue && newValue.guid === oldValue.guid) {
            // do nothing
        } else if (newValue?.guid) {
            // console.log(newValue);
            this.subGroups = (await (this.repository as ImpiloRepository).getSubGroups(newValue.guid)).results.map((subgroup) => {
                subgroup.option = subgroup.name;
                return subgroup;
            }).filter((a) => a.isActive).sort((a, b) => a.name.localeCompare(b.name));
        } else {
            if (this.subGroups) {
                this.subGroups.splice(0);
            }
        }

        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }


    selectedSubGroupChanged(newValue, oldValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    selectedPackingGroupChanged(newValue, oldValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    isActiveChanged() {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    @computedFrom('selectedPackingGroup')
    get packingGroupSelected() {
        return this.selectedPackingGroup !== null;
    }

    @computedFrom('selectedCareHome')
    get careHomeSelected() {
        return this.selectedCareHome !== null;
    }

    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: async (e: Event, args: OnEventArgs) => {
                    const patient = args.dataContext as ImpiloDb.Tables.Patient;
                    this.router.navigateToRoute('patient', { guid: patient.guid });
                }
            },
            { id: 'title', name: 'Title', field: 'title.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'firstName', name: 'First Name', field: 'firstName', sortable: true },
            { id: 'surname', name: 'Surname', field: 'surname', sortable: true },
            { id: 'careHome', name: 'Care Home', field: 'careHome.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'subGroup', name: 'Sub Group', field: 'subGroup.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'lastPrinted', name: 'Last Printed', field: 'lastPrinted', sortable: false },



        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute('patient', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute('patient', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return patientSelectedRowAction(args.rows[0]);
    }

    private readonly idMatcher = (a, b) => {
        if (a && b) {
            return a.id === b.id;
        }

        return false;
    }
}
